/* eslint-disable no-console */
/*
=========================================================
* Sugimotovisa Client Area System
* based on Material Dashboard 2 PRO React - v2.1.0 by Creative Tim (www.creative-tim.com)
* Developed by Hossein Ebrahimi (hossein.ebrahimi.a@gmail.com)
=========================================================
*/

// react default hooks
import { useState, useCallback, useEffect } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// Social login components
// import { GoogleLogin } from "react-google-login";
import { useGoogleLogin } from "@react-oauth/google";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import GoogleIcon from "@mui/icons-material/Google";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "sugimotovisa/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

// Multiple Language Support
import { useTranslation } from "react-i18next";

// Axios
import axios from "axios";

import {
  useUserInfoController,
  setEmail,
  setSocialGoogle,
  setAccessToken,
  setRefreshToken,
  setUserData,
  setProfileData,
  setInitialState,
} from "context/userInfoContext";
import MDSnackbar from "components/MDSnackbar";

function Basic() {
  // Translation Component
  const { t, i18n } = useTranslation();

  // Email and Password Fields
  const [emailField, setEmailField] = useState();
  const [passwordField, setPasswordField] = useState();
  const [rememberMe, setRememberMe] = useState(false);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  // UserInfo Context Call
  const [userInfoController, userInfoDispatch] = useUserInfoController();
  const { userData } = userInfoController;
  const navigate = useNavigate();

  const {
    REACT_APP_GOOGLE_CLIENT_ID,
    REACT_APP_GOOGLE_AUTH_URI,
    REACT_APP_GOOGLE_AUTH_REDIRECT_URI,
    REACT_APP_BASE_FRONTEND_URL,
    REACT_APP_BASE_BACKEND_URL,
  } = process.env;

  // Notification Configuration
  const [showToast, setShowToast] = useState(false);
  const openToast = () => setShowToast(true);
  const closeToast = () => setShowToast(false);
  const [toastProps, setToastProps] = useState({
    color: "success",
    icon: "check",
    title: "SuccessTitle",
    content: "SuccessContent",
    dateTime: "",
  });

  // const openGoogleLoginPage = useCallback(() => {
  //   const scope = ["openid", "email", "profile"].join(" ");
  //   const params = {
  //     redirect_uri: `${REACT_APP_BASE_FRONTEND_URL}${REACT_APP_GOOGLE_AUTH_REDIRECT_URI}`,
  //     prompt: "select_account",
  //     response_type: "code",
  //     client_id: REACT_APP_GOOGLE_CLIENT_ID,
  //     scope,
  //     access_type: "offline",
  //   };
  //   const urlParams = new URLSearchParams(params).toString();
  //   window.location = `${REACT_APP_GOOGLE_AUTH_URI}?${urlParams}`;
  // }, []);

  useEffect(() => {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    const confirmToken = params.get("confirm");
    if (confirmToken !== null) {
      // console.log(confirmToken);
      axios({
        method: "post",
        url: `${REACT_APP_BASE_BACKEND_URL}/api/v1/auth/registration/account-confirm-email/`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        data: JSON.stringify({ key: `${confirmToken}` }),
      })
        .then((loginResponse) => {
          if (loginResponse.status === 200) {
            setToastProps({
              color: "success",
              icon: "check",
              title: t("email_verify_success"),
              content: t("email_verify_success_content"),
              dateTime: "",
            });
            openToast();
          }
        })
        .catch((error) => {
          setToastProps({
            color: "error",
            icon: "error",
            title: t("email_verify_error"),
            content: t("email_verify_error_content"),
            dateTime: "",
          });
          openToast();
        });
    }
  }, []);

  function ApiLoginResponse(loginResponse) {
    setAccessToken(userInfoDispatch, loginResponse.data.access_token);
    setRefreshToken(userInfoDispatch, loginResponse.data.refresh_token);
    localStorage.setItem("accessToken", loginResponse.data.access_token);
    localStorage.setItem("refreshToken", loginResponse.data.refresh_token);
    setEmailField("");
    setPasswordField("");
    navigate("/dashboard/overview");
  }

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      // console.log(tokenResponse);
      axios({
        method: "post",
        url: `${REACT_APP_BASE_BACKEND_URL}${REACT_APP_GOOGLE_AUTH_REDIRECT_URI}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        data: JSON.stringify({ access_token: `${tokenResponse.access_token}` }),
      })
        .then((loginResponse) => {
          if (loginResponse.status === 200) {
            ApiLoginResponse(loginResponse);
          }
        })
        .catch((error) => {
          console.log("error ->", error);
          setToastProps({
            color: "error",
            icon: "error",
            title: t("login_error"),
            content: t("login_error_content"),
            dateTime: "",
          });
          openToast();
        });
    },
    scope: "openid email profile",
    flow: "implicit",
    redirect_uri: `${REACT_APP_BASE_BACKEND_URL}${REACT_APP_GOOGLE_AUTH_REDIRECT_URI}`,
  });

  const onGoogleLoginSuccess = useCallback((tokenResponse) => {
    // setEmail(userInfoDispatch, response.profileObj.email);
    // setSocialGoogle(userInfoDispatch, response.profileObj);

    // localStorage.setItem("email", response.profileObj.email);
    // Post to API endpoint for Google (Google accessToken differs from DRF accessToken)
    axios({
      method: "post",
      url: `${REACT_APP_BASE_BACKEND_URL}${REACT_APP_GOOGLE_AUTH_REDIRECT_URI}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: JSON.stringify({ access_token: `${tokenResponse.credential}` }),
    })
      .then((loginResponse) => {
        if (loginResponse.status === 200) {
          ApiLoginResponse(loginResponse);
        }
      })
      .catch((error) => {
        console.log("error ->", error);
        setToastProps({
          color: "error",
          icon: "error",
          title: t("login_error"),
          content: t("login_error_content"),
          dateTime: "",
        });
        openToast();
      });
  });

  const onUserPassLogin = () => {
    axios({
      method: "post",
      url: `${REACT_APP_BASE_BACKEND_URL}/api/v1/auth/login/`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
      data: JSON.stringify({ email: `${emailField}`, password: `${passwordField}` }),
    })
      .then((loginResponse) => {
        if (loginResponse.status === 200) {
          localStorage.setItem("email", `${emailField}`);
          ApiLoginResponse(loginResponse);
        }
      })
      .catch((error) => {
        // console.log("error ->", error);
        setToastProps({
          color: "error",
          icon: "error",
          title: t("login_error"),
          content: t("login_error_content"),
          // content: error,
          dateTime: "",
        });
        openToast();
      });
  };

  const renderToast = (
    <MDSnackbar
      color={toastProps?.color}
      icon={toastProps?.icon}
      title={toastProps?.title}
      content={toastProps?.content}
      dateTime={toastProps?.dateTime}
      open={showToast}
      onClose={closeToast}
      close={closeToast}
      bgWhite
    />
  );

  return (
    <BasicLayout image={bgImage}>
      {renderToast}
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            {t("sign_in_users")}
          </MDTypography>
          <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                {/* <GoogleIcon color="inherit" onClick={() => googleLogin()} /> */}
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <TwitterIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label={t("email")}
                fullWidth
                onChange={(e) => setEmailField(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label={t("password")}
                fullWidth
                onChange={(e) => setPasswordField(e.target.value)}
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;{t("remember_me")}
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={onUserPassLogin}>
                {t("sign_in")}
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                {t("forgot_password")}{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/reset-password/cover"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  {t("reset_verbal")}
                </MDTypography>
              </MDTypography>
            </MDBox>
            <MDBox mt={1} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                {t("dont_have_an_account")}{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up/cover"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  {t("sign_up_verbal")}
                </MDTypography>
              </MDTypography>
            </MDBox>
            <MDBox mt={1} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                {t("didint_receive_verify_email")}{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up/resend"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  {t("receive_verify_email_verbal")}
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
