/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Multiple Language Support
import { useTranslation } from "react-i18next";
import { useUserInfoController } from "context/userInfoContext";

function Overview() {
  // Translation Component
  const { t, i18n } = useTranslation();
  // UserInfo Context Call
  const [userInfoController, userInfoDispatch] = useUserInfoController();

  const { userData, profileData, accessToken, refreshToken } = userInfoController;

  // console.log(userData);
  // console.log(profileData);

  const OverallSteps = [
    t("welcome"),
    t("initial_assessment"),
    t("client_contract"),
    t("apply"),
    t("visa"),
    t("welcome_to_canada"),
  ];

  const WelcomeSteps = [t("waiting_for_approval"), t("welcome_to_sugimotovisa")];

  const InitialAssessmentSteps = [
    t("identity_check"),
    t("education_check"),
    t("language_proficiency"),
    t("work_experience"),
    t("visa_history"),
    t("financial_check"),
    t("other_requirements"),
    t("initial_assessment_result"),
  ];

  const ClientContractSteps = [
    t("client_contract"),
    t("signing_contract"),
    t("initial_payment"),
    t("start_migration_progress"),
  ];

  const ApplySteps = [
    t("application_list"),
    t("gathering_documents"),
    t("university_selection"),
    t("university_apply"),
    t("waiting_for_university_notification"),
    t("comments"),
    t("apply_result"),
  ];

  const VisaSteps = [
    t("gathering_documents"),
    t("embassy_appointment"),
    t("biometrics"),
    t("visa_submission"),
    t("comments"),
    t("visa_result"),
  ];

  const OverallStepNumber = 3;
  const CurrentStepNumber = 0;
  const CurrentSteps = ApplySteps;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={1} pb={8}>
        <Grid container justifyContent="center" sx={{ my: 4 }}>
          <Grid item xs={12} lg={8}>
            <MDBox mt={4} mb={4} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  {t("overall_progress")}: {OverallSteps[OverallStepNumber]}
                </MDTypography>
              </MDBox>
            </MDBox>
            <Card>
              <MDBox mt={0} mx={2} py={4}>
                <Stepper activeStep={OverallStepNumber} alternativeLabel>
                  {OverallSteps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </MDBox>
            </Card>

            <MDBox mt={4} mb={4} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  {t("current_progress")}: {CurrentSteps[CurrentStepNumber]}
                </MDTypography>
              </MDBox>
            </MDBox>
            <Card>
              <MDBox mt={0} mx={2} py={4}>
                <Stepper activeStep={CurrentStepNumber} alternativeLabel>
                  {CurrentSteps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
