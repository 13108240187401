import axios from "axios";

const RefreshAuth = () => {
  const { REACT_APP_BASE_BACKEND_URL, REACT_APP_BASE_FRONTEND_URL } = process.env;

  axios({
    method: "post",
    url: `${REACT_APP_BASE_BACKEND_URL}/api/v1/auth/token/refresh/`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    data: JSON.stringify({ refresh: `${localStorage.refreshToken}` }),
  }).then((response) => {
    if (response.status === 200) {
      console.log(response);
      localStorage.setItem("accessToken", response.data.access);
    }
  });
};

export default RefreshAuth;
