/*
=========================================================
* Sugimotovisa Client Area System
* based on Material Dashboard 2 PRO React - v2.1.0 by Creative Tim (www.creative-tim.com)
* Developed by Hossein Ebrahimi (hossein.ebrahimi.a@gmail.com)
=========================================================
*/

import { useState, useCallback, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsappIcon from "@mui/icons-material/WhatsApp";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/pages/profile/components/Header";
import PlatformSettings from "layouts/pages/profile/profile-overview/components/PlatformSettings";

// Data
import profilesListData from "layouts/pages/profile/profile-overview/data/profilesListData";

// Multiple Language Support
import { useTranslation } from "react-i18next";

import axios from "axios";

import { useUserInfoController } from "context/userInfoContext";

function Overview() {
  // UserInfo Context Call
  const [userInfoController, userInfoDispatch] = useUserInfoController();
  // Translation Component
  const { t, i18n } = useTranslation();
  // Backend parameters
  const { REACT_APP_BASE_BACKEND_URL } = process.env;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [citizenshipCountry, setCitizenshipCountry] = useState("Iran");
  const [mobileNumber, setMobileNumber] = useState("");
  const [socialNumber, setSocialNumber] = useState("");
  const [instagramProfile, setInstagramProfile] = useState("");
  const [facebookProfile, setFacebookProfile] = useState("");
  const [twitterProfile, setTwitterProfile] = useState("");

  useEffect(() => {
    axios({
      method: "get",
      url: `${REACT_APP_BASE_BACKEND_URL}/api/v1/profile/user/${localStorage.email}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    }).then((userDataResponse) => {
      if (userDataResponse.status === 200) {
        // console.log("user data fetched");
        setFirstName(userDataResponse.data[0].first_name);
        setLastName(userDataResponse.data[0].last_name);
      }
    });

    axios({
      method: "get",
      url: `${REACT_APP_BASE_BACKEND_URL}/api/v1/profile/userprofile/${localStorage.userId}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    }).then((userDataResponse) => {
      if (userDataResponse.status === 200) {
        const fetchedData = userDataResponse.data[0];
        setCitizenshipCountry(fetchedData.citizenshipCountry);
        setMobileNumber(fetchedData.mobileNumber);
        setSocialNumber(fetchedData.socialNumber);
        setInstagramProfile(fetchedData.instagramProfile);
        setFacebookProfile(fetchedData.facebookProfile);
        setTwitterProfile(fetchedData.twitterProfile);
      }
    });
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfileInfoCard
                title={t("profile_information")}
                description=" "
                info={{
                  full_name: `${firstName} ${lastName}`,
                  mobile: `${mobileNumber}`,
                  email: `${localStorage.email}`,
                  location: `${citizenshipCountry}`,
                }}
                social={[
                  {
                    link: `${instagramProfile}`,
                    icon: <InstagramIcon />,
                    color: "instagram",
                  },
                  {
                    link: `${facebookProfile}`,
                    icon: <FacebookIcon />,
                    color: "facebook",
                  },
                  {
                    link: `${twitterProfile}`,
                    icon: <TwitterIcon />,
                    color: "twitter",
                  },
                  {
                    link: `https://wa.me/${socialNumber}`,
                    icon: <WhatsappIcon />,
                    color: "whatsapp",
                  },
                ]}
                action={{ route: "/account/settings/", tooltip: t("edit_profile") }}
                shadow
              />
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>
            {/* <Grid item xs={12} md={6} xl={4}>
              <PlatformSettings />
            </Grid> */}
            {/* <Grid item xs={12} xl={4}>
              <ProfilesList title="conversations" profiles={profilesListData} shadow={false} />
            </Grid> */}
          </Grid>
        </MDBox>
        {/* <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h6" fontWeight="medium">
            Projects
          </MDTypography>
          <MDBox mb={1}>
            <MDTypography variant="button" color="text">
              Architects design houses
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox p={2}>
          <Grid container spacing={6} />
        </MDBox> */}
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
