import axios from "axios";
import { Button } from "@mui/material";
import { useEffect } from "react";

function downloadFromApi() {
  const { REACT_APP_BASE_BACKEND_URL, REACT_APP_BASE_FRONTEND_URL } = process.env;

  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  const userAppId = params.get("userApp");
  const docId = params.get("doc");
  const progressId = params.get("progress");
  const fname = params.get("fname");

  const closeTab = () => {
    window.opener = null;
    window.open("", "_self");
    window.close();
  };

  const downloadApiRequest = () => {
    axios({
      method: "get",
      url: `${REACT_APP_BASE_BACKEND_URL}/api/v1/apply/userapp/${userAppId}/doc/${docId}/progress/${progressId}/dl/`,
      responseType: "blob",
      headers: {
        "Content-Type": "multipart/form-data",
        // Accept: "application/json",
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    }).then((response) => {
      if (response.status === 200) {
        console.log(response);
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fname);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);

        // Close current tab
        closeTab();
      }
    });
  };

  useEffect(() => {
    downloadApiRequest();
  }, []);

  return null;
}

export default downloadFromApi;
