/*
=========================================================
* Sugimotovisa Client Area System
* based on Material Dashboard 2 PRO React - v2.1.0 by Creative Tim (www.creative-tim.com)
* Developed by Hossein Ebrahimi (hossein.ebrahimi.a@gmail.com)
=========================================================
*/

import { useState } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "sugimotovisa/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";

// Multiple Language Support
import { useTranslation } from "react-i18next";

import axios from "axios";
import MDSnackbar from "components/MDSnackbar";

function Cover() {
  // Translation Component
  const { t, i18n } = useTranslation();
  const [password1Field, setPassword1Field] = useState();
  const [password2Field, setPassword2Field] = useState();

  const [errorField, setErrorField] = useState(false);
  const { REACT_APP_BASE_BACKEND_URL } = process.env;
  // Notification Configuration
  const [showToast, setShowToast] = useState(false);
  const openToast = () => setShowToast(true);
  const closeToast = () => setShowToast(false);
  const navigate = useNavigate();

  const onUserResetPassword = () => {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    const uid = params.get("uidb64");
    const token = params.get("token");
    axios({
      method: "post",
      url: `${REACT_APP_BASE_BACKEND_URL}/api/v1/auth/password/reset/confirm/`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: JSON.stringify({
        new_password1: `${password1Field}`,
        new_password2: `${password2Field}`,
        uid: `${uid}`,
        token: `${token}`,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          setErrorField(false);
          openToast();
        }
      })
      .catch((error) => {
        // console.log("error -> ", error?.response?.data?.token[0]);
        setErrorField(true);
        openToast();
      });
    setPassword1Field("");
    setPassword2Field("");
  };

  const onUserReturn = () => {
    navigate("/authentication/sign-in/basic");
  };

  const renderToast = (
    <MDSnackbar
      color={errorField ? "error" : "success"}
      icon={errorField ? "error" : "check"}
      title={errorField ? t("password_error") : t("password_success")}
      content={errorField ? t("password_error_content") : t("password_success_content")}
      dateTime=""
      open={showToast}
      onClose={closeToast}
      close={closeToast}
      bgWhite
    />
  );
  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      {renderToast}
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            {t("reset_password")}
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            {t("reset_password_enter_new")}
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="password"
                label={t("password_new")}
                variant="standard"
                onChange={(e) => {
                  setPassword1Field(e.target.value);
                  setErrorField(false);
                }}
                fullWidth
                error={errorField}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label={t("password_new_repeat")}
                variant="standard"
                onChange={(e) => {
                  setPassword2Field(e.target.value);
                  setErrorField(false);
                }}
                fullWidth
                error={errorField}
              />
            </MDBox>
            <MDBox mt={3} mb={1}>
              <MDButton variant="gradient" color="info" onClick={onUserResetPassword} fullWidth>
                {t("reset")}
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1}>
              <MDButton variant="gradient" color="info" onClick={onUserReturn} fullWidth>
                {t("return_to_client_area")}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
