/*
=========================================================
* Sugimotovisa Client Area System
* based on Material Dashboard 2 PRO React - v2.1.0 by Creative Tim (www.creative-tim.com)
* Developed by Hossein Ebrahimi (hossein.ebrahimi.a@gmail.com)
=========================================================
*/

import { createContext, useContext, useReducer, useMemo } from "react";
import PropTypes from "prop-types";

const UserInfo = createContext();
UserInfo.displayName = "UserAuthInfo";

function UserInfoControllerProvider({ children }) {
  const initialState = {
    email: "",
    socialGoogle: "",
    accessToken: "",
    refreshToken: "",
    userData: "",
    profileData: "",
  };

  function reducer(state, action) {
    switch (action.type) {
      case "EMAIL": {
        return { ...state, email: action.value };
      }
      case "SOCIAL_GOOGLE": {
        return { ...state, socialGoogle: action.value };
      }
      case "ACCESS_TOKEN": {
        return { ...state, accessToken: action.value };
      }
      case "REFRESH_TOKEN": {
        return { ...state, refreshToken: action.value };
      }
      case "USER_DATA": {
        return { ...state, userData: action.value };
      }
      case "PROFILE_DATA": {
        return { ...state, profileData: action.value };
      }
      case "INITIAL_STATE": {
        return { initialState };
      }
      default: {
        throw new Error(`Unhandled action type: ${action.type}`);
      }
    }
  }

  const [userInfoController, userInfoDispatch] = useReducer(reducer, initialState);

  const value = useMemo(
    () => [userInfoController, userInfoDispatch],
    [userInfoController, userInfoDispatch]
  );

  return <UserInfo.Provider value={value}>{children}</UserInfo.Provider>;
}
function useUserInfoController() {
  const context = useContext(UserInfo);

  if (!context) {
    throw new Error("useUserInfoController should be used inside the UserInfoControllerProvider.");
  }

  return context;
}

// Typechecking props for the UserInfoControllerProvider
UserInfoControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setEmail = (dispatch, value) => dispatch({ type: "EMAIL", value });
const setSocialGoogle = (dispatch, value) => dispatch({ type: "SOCIAL_GOOGLE", value });
const setAccessToken = (dispatch, value) => dispatch({ type: "ACCESS_TOKEN", value });
const setRefreshToken = (dispatch, value) => dispatch({ type: "REFRESH_TOKEN", value });
const setUserData = (dispatch, value) => dispatch({ type: "USER_DATA", value });
const setProfileData = (dispatch, value) => dispatch({ type: "PROFILE_DATA", value });
const setInitialState = (dispatch, value) => dispatch({ type: "INITIAL_STATE", value });

export {
  UserInfoControllerProvider,
  useUserInfoController,
  setEmail,
  setSocialGoogle,
  setAccessToken,
  setRefreshToken,
  setUserData,
  setProfileData,
  setInitialState,
};
