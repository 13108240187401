/* eslint-disable react/prop-types */
import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import USIcon from "country-flag-icons/1x1/US.svg";
import IRIcon from "country-flag-icons/1x1/IR.svg";
import SAIcon from "country-flag-icons/1x1/SA.svg";
import RUIcon from "country-flag-icons/1x1/RU.svg";
import JPIcon from "country-flag-icons/1x1/JP.svg";

// Multiple Language Support
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export default function LanguageSelect() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Translation Component
  const { t, i18n } = useTranslation();

  const SelIcon = (props) => {
    switch (props.value) {
      case "fa":
        return IRIcon;
      case "ru":
        return RUIcon;
      case "ar":
        return SAIcon;
      case "jp":
        return JPIcon;
      case "en":
        return USIcon;
      default:
        return USIcon;
    }
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="text"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        color="primary"
      >
        <img src={USIcon} style={{ height: 20, width: 40 }} alt="website logo" />
        {t("selected_language")}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} disableRipple>
          <img src={USIcon} style={{ height: 20, width: 40 }} alt="website logo" />
          English
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          <img src={IRIcon} style={{ height: 20, width: 40 }} alt="website logo" />
          فارسی
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          <img src={SAIcon} style={{ height: 20, width: 40 }} alt="website logo" />
          العربية
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          <img src={RUIcon} style={{ height: 20, width: 40 }} alt="website logo" />
          Pусский
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          <img src={JPIcon} style={{ height: 20, width: 40 }} alt="website logo" />
          日本語
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
