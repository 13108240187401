/*
=========================================================
* Sugimotovisa Client Area System
* based on Material Dashboard 2 PRO React - v2.1.0 by Creative Tim (www.creative-tim.com)
* Developed by Hossein Ebrahimi (hossein.ebrahimi.a@gmail.com)
=========================================================
*/

import { useState } from "react";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import IllustrationLayout from "sugimotovisa/authentication/components/IllustrationLayout";

// Image
import bgImage from "assets/images/bg-register-success.png";
import logo from "assets/images/logo-ct-dark.png";

// Multiple Language Support
import { useTranslation } from "react-i18next";

function Illustration() {
  // Translation Component
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const backToLoginPage = () => {
    navigate("/authentication/sign-in/basic");
  };
  return (
    <IllustrationLayout
      title={t("signup_success")}
      description={t("please_check_your_email_for_further_instructions")}
      illustration={bgImage}
      logo={logo}
    >
      <MDBox component="form" role="form">
        <MDBox mt={4} mb={1}>
          <MDButton
            variant="gradient"
            color="info"
            size="large"
            onClick={backToLoginPage}
            fullWidth
          >
            {t("back_to_login_page")}
          </MDButton>
        </MDBox>
      </MDBox>
    </IllustrationLayout>
  );
}

export default Illustration;
