/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Sugimotovisa layouts
import Overview from "sugimotovisa/immigration/dashboards/overview";
import IdentityCheck from "sugimotovisa/immigration/initialAssessment/identityCheck";
import GatheringDocuments from "sugimotovisa/immigration/apply/gatheringDocuments";
import Download from "sugimotovisa/utils/downloadFromApi";
import PartnerDashboard from "sugimotovisa/partners/dashboard";
// Material Dashboard 2 PRO React layouts
import ProfileOverview from "layouts/pages/profile/profile-overview";
import AllProjects from "layouts/pages/profile/all-projects";
import NewUser from "layouts/pages/users/new-user";
import Settings from "layouts/pages/account/settings";
import Billing from "layouts/pages/account/billing";
import Invoice from "layouts/pages/account/invoice";
import Timeline from "layouts/pages/projects/timeline";
import PricingPage from "layouts/pages/pricing-page";
import Widgets from "layouts/pages/widgets";
import RTL from "layouts/pages/rtl";
import Charts from "layouts/pages/charts";
import Notifications from "layouts/pages/notifications";
import Kanban from "layouts/applications/kanban";
import Wizard from "layouts/applications/wizard";
import DataTables from "layouts/applications/data-tables";
import Calendar from "layouts/applications/calendar";
import NewProduct from "layouts/ecommerce/products/new-product";
import EditProduct from "layouts/ecommerce/products/edit-product";
import ProductPage from "layouts/ecommerce/products/product-page";
import OrderList from "layouts/ecommerce/orders/order-list";
import OrderDetails from "layouts/ecommerce/orders/order-details";
import SignInBasic from "sugimotovisa/authentication/sign-in/basic";
import SignInCover from "sugimotovisa/authentication/sign-in/cover";
import SignInIllustration from "sugimotovisa/authentication/sign-in/illustration";
import SignUpCover from "sugimotovisa/authentication/sign-up/cover";
import SignUpSuccess from "sugimotovisa/authentication/sign-up/success";
import SignUpResend from "sugimotovisa/authentication/sign-up/resend";
import ResetCover from "sugimotovisa/authentication/reset-password/cover";
import ResetForm from "sugimotovisa/authentication/reset-password/form";
// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/team-3.jpg";
import ApplicationList from "sugimotovisa/immigration/apply/applicationList";

const DEV = false;

const routes = [
  { type: "title", title: "dear_client", key: "title_clients", visible: true },
  { type: "title", title: "dear_partner", key: "title_partners", visible: false },
  {
    type: "collapse",
    name: "default_user",
    key: "default_user",
    icon: <MDAvatar src={profilePicture} alt="Default User" size="sm" />,
    visible: true,
    collapse: [
      {
        name: "my_profile",
        key: "my_profile",
        route: "/account/profile",
        component: <ProfileOverview />,
      },
      {
        name: "account_settings",
        key: "account_settings",
        route: "/account/settings",
        component: <Settings />,
      },
      {
        name: "logout",
        key: "logout",
        route: "/authentication/sign-in/basic",
        component: <SignInBasic />,
      },
    ],
  },
  // Add other routes here to register on DOM
  {
    type: "collapse",
    name: "missing_routes",
    key: "missing_routes",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    visible: false,
    collapse: [
      {
        name: "reset_password",
        key: "reset_password",
        route: "/authentication/reset-password/cover",
        component: <ResetCover />,
      },
      {
        name: "reset_password_form",
        key: "reset_password_form",
        route: "/authentication/reset-password/form",
        component: <ResetForm />,
      },
      {
        name: "create_account",
        key: "create_account",
        route: "/authentication/sign-up/cover",
        component: <SignUpCover />,
      },
      {
        name: "create_account_success",
        key: "create_account_success",
        route: "/authentication/sign-up/success",
        component: <SignUpSuccess />,
      },
      {
        name: "create_account_resend",
        key: "create_account_resend",
        route: "/authentication/sign-up/resend",
        component: <SignUpResend />,
      },
      {
        name: "download",
        key: "download",
        route: "/download",
        component: <Download />,
      },
    ],
  },
  { type: "divider", key: "divider-0", visible: true },
  { type: "title", title: "migration_section", key: "title_migration_section", visible: true },

  {
    type: "collapse",
    name: "dashboard",
    key: "dashboard",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    visible: true,
    collapse: [
      {
        name: "overview",
        key: "overview",
        route: "/dashboard/overview",
        component: <Overview />,
      },
    ],
  },
  {
    type: "collapse",
    name: "initial_assessment",
    key: "initial_assessment",
    icon: <Icon fontSize="medium">assessment</Icon>,
    visible: false,
    collapse: [
      {
        name: "identity_check",
        key: "identity_check",
        route: "/initial_assessment/identity_check",
        component: <IdentityCheck />,
      },
      {
        name: "education_check",
        key: "education_check",
        route: "/initial_assessment/education_check",
        component: <Overview />,
      },
      {
        name: "language_proficiency",
        key: "language_proficiency",
        route: "/initial_assessment/language_proficiency",
        component: <Overview />,
      },
      {
        name: "work_experience",
        key: "work_experience",
        route: "/initial_assessment/work_experience",
        component: <Overview />,
      },
      {
        name: "visa_history",
        key: "visa_history",
        route: "/initial_assessment/visa_history",
        component: <Overview />,
      },
      {
        name: "financial_check",
        key: "financial_check",
        route: "/initial_assessment/financial_check",
        component: <Overview />,
      },
      {
        name: "other_requirements",
        key: "other_requirements",
        route: "/initial_assessment/other_requirements",
        component: <Overview />,
      },
      {
        name: "initial_assessment_result",
        key: "initial_assessment_result",
        route: "/initial_assessment/initial_assessment_result",
        component: <Overview />,
      },
    ],
  },
  {
    type: "collapse",
    name: "client_contract",
    key: "client_contract",
    icon: <Icon fontSize="medium">note_alt</Icon>,
    visible: false,
    collapse: [
      {
        name: "client_contract",
        key: "client_contract",
        route: "/contract/client_contract",
        component: <Overview />,
      },
      {
        name: "signing_contract",
        key: "signing_contract",
        route: "/contract/signing_contract",
        component: <Overview />,
      },
      {
        name: "initial_payment",
        key: "initial_payment",
        route: "/contract/initial_payment",
        component: <Overview />,
      },
      {
        name: "start_migration_progress",
        key: "start_migration_progress",
        route: "/contract/start_migration_progress",
        component: <Overview />,
      },
    ],
  },
  {
    type: "collapse",
    name: "apply",
    key: "apply",
    icon: <Icon fontSize="medium">school</Icon>,
    visible: true,
    collapse: [
      {
        name: "application_list",
        key: "application_list",
        route: "/apply/application_list",
        component: <ApplicationList />,
      },
      {
        name: "gathering_documents",
        key: "gathering_documents",
        route: "/apply/gathering_documents",
        component: <GatheringDocuments />,
      },
      // {
      //   name: "university_selection",
      //   key: "university_selection",
      //   route: "/apply/university_selection",
      //   component: <Overview />,
      // },
      // {
      //   name: "university_apply",
      //   key: "university_apply",
      //   route: "/apply/university_apply",
      //   component: <Overview />,
      // },
      // {
      //   name: "waiting_for_university_notification",
      //   key: "waiting_for_university_notification",
      //   route: "/apply/waiting_for_university_notification",
      //   component: <Overview />,
      // },
      // {
      //   name: "comments",
      //   key: "comments",
      //   route: "/apply/comments",
      //   component: <Overview />,
      // },
      // {
      //   name: "apply_result",
      //   key: "apply_result",
      //   route: "/apply/apply_result",
      //   component: <Overview />,
      // },
    ],
  },
  {
    type: "collapse",
    name: "visa",
    key: "visa",
    icon: <Icon fontSize="medium">approval</Icon>,
    visible: false,
    collapse: [
      {
        name: "gathering_documents",
        key: "gathering_documents",
        route: "/visa/gathering_documents",
        component: <Overview />,
      },
      {
        name: "embassy_appointment",
        key: "embassy_appointment",
        route: "/visa/embassy_appointment",
        component: <Overview />,
      },
      {
        name: "biometrics",
        key: "biometrics",
        route: "/visa/biometrics",
        component: <Overview />,
      },
      {
        name: "visa_submission",
        key: "visa_submission",
        route: "/visa/visa_submission",
        component: <Overview />,
      },
      {
        name: "comments",
        key: "comments",
        route: "/visa/comments",
        component: <Overview />,
      },
      {
        name: "visa_result",
        key: "visa_result",
        route: "/visa/visa_result",
        component: <Overview />,
      },
    ],
  },

  { type: "divider", key: "divider-1", visible: true },
  { type: "title", title: "partner_section", key: "title_partner_section", visible: true },

  {
    type: "collapse",
    name: "dashboard",
    key: "partner_dashboard_root",
    icon: <Icon fontSize="medium">3p</Icon>,
    visible: true,
    collapse: [
      {
        name: "partner_dashboard",
        key: "partner_dashboard",
        route: "/partner/dashboard",
        component: <PartnerDashboard />,
      },
      {
        name: "partner_client_dashboard",
        key: "partner_client_dashboard",
        route: "/partner/client_dashboard",
        component: <Overview />,
      },
    ],
  },
  {
    type: "collapse",
    name: "commission_panel",
    key: "commission_panel",
    icon: <Icon fontSize="medium">card_giftcard</Icon>,
    visible: false,
    noCollapse: true,
    route: "/partner/commission_panel",
    component: <Overview />,
  },

  { type: "divider", key: "divider-2", visible: false },
  { type: "title", title: "general_section", key: "title_general_section", visible: false },
  {
    type: "collapse",
    name: "payments",
    key: "payments",
    icon: <Icon fontSize="medium">paid</Icon>,
    visible: false,
    noCollapse: true,
    route: "/payments",
    component: <Overview />,
  },
  {
    type: "collapse",
    name: "messages",
    key: "messages",
    icon: <Icon fontSize="medium">message</Icon>,
    visible: false,
    noCollapse: true,
    route: "/messages",
    component: <Overview />,
  },

  // #region Developer Pages
  { type: "divider", key: "divider-3", visible: DEV },
  { type: "title", title: "Developer Tools", key: "title_developer", visible: DEV },

  {
    type: "collapse",
    name: "Pages",
    key: "pages",
    icon: <Icon fontSize="medium">image</Icon>,
    visible: DEV,
    collapse: [
      {
        name: "profile",
        key: "profile",
        collapse: [
          {
            name: "Profile Overview",
            key: "profile-overview",
            route: "/pages/profile/profile-overview",
            component: <ProfileOverview />,
          },
          {
            name: "All Projects",
            key: "all-projects",
            route: "/pages/profile/all-projects",
            component: <AllProjects />,
          },
        ],
      },
      {
        name: "Users",
        key: "users",
        collapse: [
          {
            name: "New User",
            key: "new-user",
            route: "/pages/users/new-user",
            component: <NewUser />,
          },
        ],
      },
      {
        name: "Account",
        key: "account",
        collapse: [
          {
            name: "Settings",
            key: "settings",
            route: "/pages/account/settings",
            component: <Settings />,
          },
          {
            name: "Billing",
            key: "billing",
            route: "/pages/account/billing",
            component: <Billing />,
          },
          {
            name: "Invoice",
            key: "invoice",
            route: "/pages/account/invoice",
            component: <Invoice />,
          },
        ],
      },
      {
        name: "Projects",
        key: "projects",
        collapse: [
          {
            name: "Timeline",
            key: "timeline",
            route: "/pages/projects/timeline",
            component: <Timeline />,
          },
        ],
      },
      {
        name: "Pricing Page",
        key: "pricing-page",
        route: "/pages/pricing-page",
        component: <PricingPage />,
      },
      { name: "RTL", key: "rtl", route: "/pages/rtl", component: <RTL /> },
      { name: "Widgets", key: "widgets", route: "/pages/widgets", component: <Widgets /> },
      { name: "Charts", key: "charts", route: "/pages/charts", component: <Charts /> },
      {
        name: "Notifications",
        key: "notifications",
        route: "/pages/notifications",
        component: <Notifications />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Applications",
    key: "applications",
    icon: <Icon fontSize="medium">apps</Icon>,
    visible: DEV,
    collapse: [
      {
        name: "Kanban",
        key: "kanban",
        route: "/applications/kanban",
        component: <Kanban />,
      },
      {
        name: "Wizard",
        key: "wizard",
        route: "/applications/wizard",
        component: <Wizard />,
      },
      {
        name: "Data Tables",
        key: "data-tables",
        route: "/applications/data-tables",
        component: <DataTables />,
      },
      {
        name: "Calendar",
        key: "calendar",
        route: "/applications/calendar",
        component: <Calendar />,
      },
    ],
  },
  // #endregion
];

export default routes;
