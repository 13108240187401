import { useState, useCallback, useEffect } from "react";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FormField from "layouts/pages/account/components/FormField";
import Autocomplete from "@mui/material/Autocomplete";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import MDTypography from "components/MDTypography";
import myStyles from "sugimotovisa/immigration/apply/myStyles.css";
import axios from "axios";
import { useTranslation } from "react-i18next";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import { FormControlLabel } from "@mui/material";

import MDButton from "components/MDButton";

function ApplicationList() {
  const { t, i18n } = useTranslation();
  const { REACT_APP_BASE_BACKEND_URL } = process.env;

  const [expanded, setExpanded] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [appUsers, setAppUsers] = useState();
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [userApplication, setUserApplication] = useState();
  const [userAppRequirement, setUserAppRequirement] = useState([]);

  useEffect(() => {
    axios({
      method: "get",
      url: `${REACT_APP_BASE_BACKEND_URL}/api/v1/apply/userapp/`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    }).then((response) => {
      if (response.status === 200) {
        setUserApplication(response.data);

        // Extract Users from application list
        const users = response.data.map((q) => q.user);
        setAppUsers(users);
        if (users.length > 0)
          setSelectedUser(
            `AC${`000000${users[0].id}`.slice(-5)} ${users[0].first_name} ${users[0].last_name} (${
              users[0].email
            })`
          );
        setUserAppRequirement([]);
        response.data.forEach((application) => {
          const userAppId = application.id;
          axios({
            method: "get",
            url: `${REACT_APP_BASE_BACKEND_URL}/api/v1/apply/userapp/${userAppId}/requirement/`,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.accessToken}`,
            },
          }).then((response2) => {
            if (response2.status === 200) {
              setUserAppRequirement((prevState) => [...prevState, response2.data]);
            }
          });
        });
      }
    });
  }, []);
  if (userApplication !== undefined && userAppRequirement !== undefined)
    if (userAppRequirement.length === userApplication.length && userApplication.length > 0)
      return (
        <DashboardLayout>
          <DashboardNavbar />
          {appUsers !== undefined ? (
            <>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                flexWrap="wrap"
                my={2}
                mx={2}
              >
                <Grid container spacing={6}>
                  <Grid item xs={12} sm={5}>
                    <Autocomplete
                      defaultValue={`AC${`000000${appUsers[0].id}`.slice(-5)} ${
                        appUsers[0].first_name
                      } ${appUsers[0].last_name} (${appUsers[0].email})`}
                      options={appUsers
                        .map(
                          (value, key) =>
                            `AC${`000000${value.id}`.slice(-5)} ${value.first_name} ${
                              value.last_name
                            } (${value.email})`
                        )
                        .filter((itm, pos, self) => self.indexOf(itm) === pos)}
                      renderInput={(params) => (
                        <FormField
                          {...params}
                          label={t("select_user")}
                          InputLabelProps={{ shrink: true }}
                          value={selectedUser}
                          onSelect={(e) => setSelectedUser(e.target.value)}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="flex-end"
                flexWrap="wrap"
                my={2}
                mx={2}
              >
                <MDBox ml="auto">
                  <div>
                    <p>{selectedUser}</p>
                  </div>
                </MDBox>
              </MDBox>
            </>
          ) : null}

          {userApplication.map((application) =>
            `AC${`000000${application.user.id}`.slice(-5)} ${application.user.first_name} ${
              application.user.last_name
            } (${application.user.email})` === selectedUser ? (
              <Accordion
                expanded={expanded === `panel${application.id}`}
                onChange={handleChange(`panel${application.id}`)}
                // dir={
                //   application.applyProgram === null
                //     ? "ltr"
                //     : application.applyProgram.descriptionLanguage.rtl === true
                //     ? "rtl"
                //     : "ltr"
                // }
                dir="ltr"
                key={application.id}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${application.id}bh-content`}
                  id={`panel${application.id}bh-header`}
                >
                  <Typography sx={{ width: "50%", flexShrink: 0 }}>
                    {application.applyProgram === null
                      ? application.description
                      : application.applyProgram.name}
                  </Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    {application.applyProgram === null
                      ? " "
                      : `${application.applyProgram.school}, ${application.applyProgram.city}, ${application.applyProgram.province}, ${application.applyProgram.country}`}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {application.applyProgram !== null ? (
                      <>
                        <h1>{application.applyProgram.name}</h1>
                        <h2>
                          {application.applyProgram.school}, {application.applyProgram.city},{" "}
                          {application.applyProgram.province}, {application.applyProgram.country}
                        </h2>
                        <h3>Program level: {application.applyProgram.programLevel}</h3>
                        <h3>Length: {application.applyProgram.programLength}</h3>
                        <h3>
                          Tuition Fee: {application.applyProgram.currency.symbol}
                          {application.applyProgram.tuitionFeePerYear}{" "}
                          {application.applyProgram.currency.symbolName} / Year
                        </h3>
                        <h3>
                          Cost of Living: {application.applyProgram.currency.symbol}
                          {application.applyProgram.costOfLivingPerYear}{" "}
                          {application.applyProgram.currency.symbolName} / Year
                        </h3>
                        <p>Program Description:</p>
                        <p>{application.applyProgram.description}</p>

                        {userAppRequirement.map((reqList) => {
                          if (reqList[0].applyProgramRequirement.length === 0) return " ";
                          if (reqList[0].applyProgram === application.applyProgram.id)
                            return (
                              <>
                                <h3>Admission Requirements</h3>
                                {reqList[0].applyProgramRequirement.map((req) => {
                                  if (req.isLngTest) return " ";
                                  return (
                                    <h5>
                                      {req.requirementTitle}: {req.requirementValue}
                                    </h5>
                                  );
                                })}
                                <h3>English Proficiency Requirements:</h3>
                                <h5>
                                  For direct admission, applicant must be from an English speaking
                                  country or meet either of the following English minimum test
                                  scores:
                                </h5>
                                {reqList[0].applyProgramRequirement.map((req) => {
                                  if (req.isLngTest)
                                    return (
                                      <h5>
                                        {req.languageTest.testName}: {req.overall}
                                      </h5>
                                    );
                                  return " ";
                                })}
                              </>
                            );
                          return "";
                        })}
                        <Typography> &nbsp; </Typography>
                        <Typography> &nbsp; </Typography>
                        <FormControlLabel
                          control={<Switch />} // defaultChecked
                          label="Select this Application"
                          dir="rtl"
                        />
                      </>
                    ) : (
                      <>
                        <h1>{application.description}</h1>
                        <p>
                          This is a general user application. The documents uploaded in this
                          application are used in all applications
                        </p>
                      </>
                    )}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ) : null
          )}
          <div>
            <h5> &nbsp; </h5>
            <h5> &nbsp; </h5>
            <h5> &nbsp; </h5>
          </div>
          <Footer />
        </DashboardLayout>
      );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Footer />
    </DashboardLayout>
  );
}

export default ApplicationList;
