/*
=========================================================
* Sugimotovisa Client Area System
* based on Material Dashboard 2 PRO React - v2.1.0 by Creative Tim (www.creative-tim.com)
* Developed by Hossein Ebrahimi (hossein.ebrahimi.a@gmail.com)
=========================================================
*/

import { useState, useRef } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
//  User Info context
import { useUserInfoController, setProfileData } from "context/userInfoContext";
// Multiple Language Support
import { useTranslation } from "react-i18next";
import defaultAvatar from "assets/images/defaultAvatar.png";
import { Button } from "@mui/material";
import axios from "axios";

function Header() {
  const [visible, setVisible] = useState(true);

  const handleSetVisible = () => setVisible(!visible);

  // UserInfo Context Call
  const [userInfoController, userInfoDispatch] = useUserInfoController();
  const { userData, profileData } = userInfoController;
  const { REACT_APP_BASE_BACKEND_URL } = process.env;

  // Translation Component
  const { t, i18n } = useTranslation();

  const profileImageRef = useRef();

  const handleProfileImageUpload = (e) => {
    const [file] = e.target.files;
    const fd = new FormData();
    fd.append("profileImage", file);
    axios({
      method: "put",
      url: `${REACT_APP_BASE_BACKEND_URL}/api/v1/profile/userprofile/${localStorage.userId}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
      data: fd,
    })
      .then((response) => {
        setProfileData(userInfoDispatch, response.data);
        // console.log(response);
      })
      .catch((error) => console.log("error ->", error));
  };

  return (
    <Card id="profile">
      <MDBox p={2}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <Button onClick={() => profileImageRef.current.click()}>
              <MDAvatar
                src={
                  profileData?.profileImage !== null
                    ? `${REACT_APP_BASE_BACKEND_URL}${profileData?.profileImage}`
                    : defaultAvatar
                }
                alt="profile-image"
                size="xl"
                shadow="sm"
              />
            </Button>
            <input
              ref={profileImageRef}
              onChange={handleProfileImageUpload}
              // onClick={clearInputFile}
              multiple={false}
              type="file"
              accept="image/jpeg,image/png"
              hidden
            />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {`${userData?.first_name} ${userData?.last_name}`}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="medium">
                {t("client")}
              </MDTypography>
            </MDBox>
          </Grid>
          {/* <Grid item xs={12} md={6} lg={3} sx={{ ml: "auto" }}>
            <MDBox
              display="flex"
              justifyContent={{ md: "flex-end" }}
              alignItems="center"
              lineHeight={1}
            >
              <MDTypography variant="caption" fontWeight="regular">
                Switch to {visible ? "invisible" : "visible"}
              </MDTypography>
              <MDBox ml={1}>
                <Switch checked={visible} onChange={handleSetVisible} />
              </MDBox>
            </MDBox>
          </Grid> */}
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Header;
