import { useState, useCallback, useEffect, useRef } from "react";
import { useToasts } from "react-toast-notifications";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import myStyles from "sugimotovisa/immigration/apply/myStyles.css";
import PropTypes from "prop-types";

import axios from "axios";
import { useUserInfoController } from "context/userInfoContext";
import { useTranslation } from "react-i18next";
import GatheringDocuments from "sugimotovisa/immigration/apply/gatheringDocuments";

import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  Status,
  Button,
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
  MessageSeparator,
  Avatar,
  InputToolbox,
  AttachmentButton,
  SendButton,
  InfoButton,
  ConversationHeader,
  VoiceCallButton,
  VideoCallButton,
} from "@chatscope/chat-ui-kit-react";

import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

import logoIco from "assets/images/logo-ct-dark.png";
import defaultAvatar from "assets/images/defaultAvatar.png";

import RefreshAuth from "sugimotovisa/utils/refreshAuth";

function gatheringDocumentsDiscussion({ userAppId, docId, selectedUser }) {
  const { t, i18n } = useTranslation();
  // UserInfo Context Call
  const [userInfoController, userInfoDispatch] = useUserInfoController();

  const { userData, profileData } = userInfoController;

  const { REACT_APP_BASE_BACKEND_URL, REACT_APP_BASE_FRONTEND_URL } = process.env;

  const inputRef = useRef();

  const inputFileRef = useRef();

  const [msgInputValue, setMsgInputValue] = useState("");

  const [userAppData, setUserAppData] = useState();

  const [chatData, setChatData] = useState();

  const [attachment, setAttachment] = useState();

  const [oversized, setOversized] = useState(false);

  const MAX_FILE_SIZE_BYTE = 52428800;

  const handleSend = (message) => {
    console.log(message);
    const fd = new FormData();
    fd.append("description", message);
    if (attachment !== undefined) fd.append("file", attachment);
    axios({
      method: "post",
      url: `${REACT_APP_BASE_BACKEND_URL}/api/v1/apply/userapp/${userAppId}/doc/${docId}/progress/`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
      data: fd,
    }).then((response) => {
      if (response.status === 201) {
        setChatData([...chatData, response.data]);
        setAttachment(undefined);
        setOversized(false);
        console.log(response.data);
        RefreshAuth();
      }
    });

    setMsgInputValue("");
    inputRef.current.focus();
  };

  const handleInputFile = (e) => {
    const [file] = e.target.files;
    if (file === undefined) {
      setOversized(false);
      setAttachment(file);
    } else if (file.size > MAX_FILE_SIZE_BYTE) {
      setOversized(true);
      setAttachment(undefined);
    } else {
      setOversized(false);
      setAttachment(file);
    }
    console.log(file);
  };

  /* eslint-disable no-param-reassign */
  const clearInputFile = (e) => {
    e.target.value = "";
  };
  /* eslint-enable no-param-reassign */

  useEffect(() => {
    axios({
      method: "get",
      url: `${REACT_APP_BASE_BACKEND_URL}/api/v1/apply/userapp/${userAppId}/doc/${docId}/`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    }).then((response) => {
      if (response.status === 200) {
        if (response.data.length > 0) {
          setUserAppData(response.data[0]);
        }
        console.log(response.data[0]);
      }
    });
    axios({
      method: "get",
      url: `${REACT_APP_BASE_BACKEND_URL}/api/v1/apply/userapp/${userAppId}/doc/${docId}/progress/`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    }).then((response) => {
      if (response.status === 200) {
        setChatData(response.data);
        RefreshAuth();
      } else {
        RefreshAuth();
      }
    });
  }, []);

  if (chatData !== undefined && userAppData !== undefined)
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <div style={{ display: "flex", justifyContent: "flex-end", dir: "ltr" }}>
          <Button
            border
            onClick={() => {
              setChatData(undefined);
            }}
          >
            Back
          </Button>
        </div>

        <div style={{ position: "relative", height: "550px" }} dir="ltr">
          <ChatContainer>
            <ConversationHeader>
              <Avatar src={logoIco} name="Passport" />
              <ConversationHeader.Content>
                <span
                  style={{
                    color: "black",
                    alignSelf: "flex-start",
                    fontSize: "1.0em",
                    fontWeight: "bolder",
                  }}
                >
                  {`${userAppData.document}  ..::..  ${selectedUser}`}
                </span>
                <span
                  style={{
                    color: "black",
                    alignSelf: "flex-start",
                    fontSize: "0.75em",
                    fontWeight: "lighter",
                  }}
                >
                  {userAppData.userApp.applyProgram === null
                    ? `General Documents`
                    : `${userAppData.userApp.applyProgram} | ${userAppData.userApp.school} | ${userAppData.userApp.country}`}
                </span>
                <span
                  style={{
                    color: `${userAppData.required ? "red" : "blue"}`,
                    alignSelf: "flex-start",
                    fontSize: "0.75em",
                    fontWeight: "bold",
                  }}
                >
                  {userAppData.required ? "Required" : "Optional"}
                </span>
              </ConversationHeader.Content>
              {/* <ConversationHeader.Actions>
              <VoiceCallButton />

              <VideoCallButton />

              <InfoButton />
            </ConversationHeader.Actions> */}
            </ConversationHeader>

            {/* <MessageList typingIndicator={<TypingIndicator content="Sugimotovisa Admin is typing" />}> */}
            <MessageList>
              {/* <MessageSeparator content="Saturday, 30 July 2022" /> */}
              <Message
                model={{
                  // message: chat.description,
                  // sentTime: chat.dateTime,
                  sender: "Sugimotovisa System Admin",
                  direction: "incoming",
                }}
                payload={`<p>${userAppData.description}</p>`}
                key="0"
              >
                <Avatar src={defaultAvatar} name="Sugimotovisa System Admin" />
              </Message>
              <div
                style={{
                  fontSize: "0.6em",
                  textAlign: "left",
                }}
              >
                <p>Sugimotovisa System Admin</p>
                <p>{`${new Date(userAppData.creationDate).toLocaleTimeString("fa")} ${new Date(
                  userAppData.creationDate
                ).toLocaleDateString("fa")}`}</p>
              </div>
              {chatData.map((chat) => (
                <>
                  <Message
                    model={{
                      // message: chat.description,
                      sentTime: chat.dateTime,
                      sender: `${chat.user.first_name} ${chat.user.last_name} (${chat.user.username})`,
                      direction:
                        parseInt(localStorage.userId, 10) === chat.user.id
                          ? "outgoing"
                          : "incoming",
                    }}
                    payload={
                      chat.fullPath === ""
                        ? `<p>${chat.description}</p>`
                        : `<p>${chat.description}</p> <a href="${REACT_APP_BASE_FRONTEND_URL}/download?userApp=${userAppId}&doc=${docId}&progress=${chat.id}&fname=${chat.fileName}.${chat.fileExt}" target="_blank">${chat.fileName}.${chat.fileExt}</a>`
                    }
                    key={chat.id}
                    // avatarSpacer
                  >
                    <Avatar
                      src={
                        parseInt(localStorage.userId, 10) === chat.user.id &&
                        profileData?.profileImage !== null
                          ? `${REACT_APP_BASE_BACKEND_URL}${profileData?.profileImage}`
                          : defaultAvatar
                      }
                      name={chat.user.first_name}
                    />
                  </Message>
                  <div
                    style={{
                      fontSize: "0.6em",
                      textAlign:
                        parseInt(localStorage.userId, 10) === chat.user.id ? "right" : "left",
                    }}
                  >
                    <p>
                      {`${chat.user.first_name} ${chat.user.last_name} (${chat.user.username})`}
                    </p>
                    <p>{`${new Date(chat.dateTime).toLocaleTimeString("fa")} ${new Date(
                      chat.dateTime
                    ).toLocaleDateString("fa")}`}</p>
                  </div>
                </>
              ))}
            </MessageList>

            <div
              as={MessageInput}
              style={{
                display: "flex",

                flexDirection: "row",

                borderTop: "1px dashed #d1dbe4",
              }}
            >
              <MessageInput
                ref={inputRef}
                onChange={(msg) => setMsgInputValue(msg)}
                value={msgInputValue}
                sendButton={false}
                attachButton={false}
                onSend={handleSend}
                placeholder="Type message here"
                style={{
                  flexGrow: 1,

                  borderTop: 0,

                  flexShrink: "initial",
                }}
              />
              <SendButton
                onClick={() => handleSend(msgInputValue)}
                disabled={msgInputValue.length === 0}
                style={{
                  fontSize: "1.2em",

                  marginLeft: 0,

                  paddingLeft: "0.2em",

                  paddingRight: "0.2em",
                }}
              />
              <AttachmentButton
                onClick={() => inputFileRef.current.click()}
                style={{
                  fontSize: "1.2em",

                  paddingLeft: "0.2em",

                  paddingRight: "0.2em",
                }}
                // disabled={attachment !== undefined}
              />
              <input
                ref={inputFileRef}
                onChange={handleInputFile}
                onClick={clearInputFile}
                multiple={false}
                type="file"
                hidden
              />
              {/* <InfoButton
                onClick={() => alert("Important message!")}
                style={{
                  fontSize: "1.2em",

                  paddingLeft: "0.2em",

                  paddingRight: "0.2em",
                }}
              /> */}
              {attachment !== undefined ? (
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    setAttachment(undefined);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              ) : (
                <IconButton aria-label="delete" disabled color="primary">
                  <DeleteIcon />
                </IconButton>
              )}
            </div>

            {attachment !== undefined ? (
              <div
                as={InputToolbox}
                dir="ltr"
                style={{
                  fontSize: "0.6em",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Status status="available" size="lg">
                  <div
                    style={{
                      color: "black",
                    }}
                  >
                    {`${attachment.name}    |   size:${
                      attachment.size <= 1024 * 1024
                        ? `${Number(attachment.size / 1024).toFixed(1)} KB`
                        : `${Number(attachment.size / 1024 / 1024).toFixed(1)} MB`
                    }`}
                  </div>
                </Status>
              </div>
            ) : (
              <div
                as={InputToolbox}
                dir="ltr"
                style={{
                  fontSize: "0.6em",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Status status={oversized ? "unavailable" : "dnd"} size="lg">
                  <div
                    style={{
                      color: "black",
                    }}
                  >
                    {oversized ? `${t("max_file_size_reached")}` : `${t("no_attachment")}`}
                  </div>
                </Status>
              </div>
            )}
          </ChatContainer>
        </div>
        <div>
          <h5> &nbsp; </h5>
          <h5> &nbsp; </h5>
        </div>
        <Footer />
      </DashboardLayout>
    );

  return <GatheringDocuments />;
}

gatheringDocumentsDiscussion.defaultProps = {
  userAppId: 0,
  docId: 0,
};

gatheringDocumentsDiscussion.propTypes = {
  userAppId: PropTypes.number,
  docId: PropTypes.number,
};

export default gatheringDocumentsDiscussion;
