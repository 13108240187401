/*
=========================================================
* Sugimotovisa Client Area System
* based on Material Dashboard 2 PRO React - v2.1.0 by Creative Tim (www.creative-tim.com)
* Developed by Hossein Ebrahimi (hossein.ebrahimi.a@gmail.com)
=========================================================
*/

import { useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Authentication layout components
import CoverLayout from "sugimotovisa/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";

// Multiple Language Support
import { useTranslation } from "react-i18next";

import axios from "axios";

function Cover() {
  // Translation Component
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const [usernameField, setUsernameField] = useState("");
  const [emailField, setEmailField] = useState("");
  const [password1Field, setPassword1Field] = useState("");
  const [password2Field, setPassword2Field] = useState("");
  const [termsAcceptField, setTermsAcceptField] = useState(false);

  // Notification Configuration
  const [showToast, setShowToast] = useState(false);
  const [toastProps, setToastProps] = useState({
    color: "success",
    icon: "check",
    title: "SuccessTitle",
    content: "SuccessContent",
    dateTime: "",
  });
  const openToast = () => setShowToast(true);
  const closeToast = () => setShowToast(false);

  const { REACT_APP_BASE_BACKEND_URL } = process.env;

  const onUserSignUp = () => {
    if (termsAcceptField === false) {
      setToastProps({
        color: "error",
        icon: "error",
        title: t("registration_error"),
        content: t("you_must_accept_terms_and_conditions"),
        dateTime: "",
      });
      openToast();
      return;
    }

    axios({
      method: "post",
      url: `${REACT_APP_BASE_BACKEND_URL}/api/v1/auth/registration/`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: JSON.stringify({
        username: `${usernameField}`,
        email: `${emailField}`,
        password1: `${password1Field}`,
        password2: `${password2Field}`,
      }),
    })
      .then((response) => {
        if (response.status === 201) {
          navigate("/authentication/sign-up/success");
        }
      })
      .catch((error) => {
        // console.log("error ->", error);
        const usernameErrors = error.response?.data?.username;
        const emailErrors = error.response?.data?.email;
        const password1Errors = error.response?.data?.password1;
        const password2Errors = error.response?.data?.password2;
        const nonFieldErrors = error.response?.data?.non_field_errors;
        // console.log("error ->", error.response);
        let errorContent = "";
        if (usernameErrors !== undefined) errorContent = `${usernameErrors[0]}`;
        else if (emailErrors !== undefined) errorContent = `${emailErrors[0]}`;
        else if (password1Errors !== undefined)
          errorContent = `Password error: ${password1Errors[0]}`;
        else if (password2Errors !== undefined)
          errorContent = `Password error: ${password2Errors[0]}`;
        else if (nonFieldErrors !== undefined) errorContent = `${nonFieldErrors[0]}`;

        setToastProps({
          color: "error",
          icon: "error",
          title: t("registration_error"),
          content: errorContent,
          dateTime: "",
        });
        openToast();
      });

    setPassword1Field("");
    setPassword2Field("");
  };

  const renderToast = (
    <MDSnackbar
      color={toastProps?.color}
      icon={toastProps?.icon}
      title={toastProps?.title}
      content={toastProps?.content}
      dateTime={toastProps?.dateTime}
      open={showToast}
      onClose={closeToast}
      close={closeToast}
      bgWhite
    />
  );

  return (
    <CoverLayout image={bgImage}>
      {renderToast}
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            {t("join_us_today")}
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            {t("enter_your_email_and_password_to_register")}
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={1}>
              <MDInput
                type="text"
                label={t("username")}
                variant="standard"
                onChange={(e) => {
                  setUsernameField(e.target.value);
                }}
                fullWidth
              />
            </MDBox>
            <MDBox mb={1}>
              <MDInput
                type="email"
                label={t("email")}
                variant="standard"
                onChange={(e) => {
                  setEmailField(e.target.value);
                }}
                fullWidth
              />
            </MDBox>
            <MDBox mb={1}>
              <MDInput
                type="password"
                label={t("password")}
                variant="standard"
                onChange={(e) => {
                  setPassword1Field(e.target.value);
                }}
                value={password1Field}
                fullWidth
              />
            </MDBox>
            <MDBox mb={1}>
              <MDInput
                type="password"
                label={t("password_repeat")}
                variant="standard"
                onChange={(e) => {
                  setPassword2Field(e.target.value);
                }}
                value={password2Field}
                fullWidth
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox
                onChange={(e) => {
                  setTermsAcceptField(e.target.checked);
                }}
              />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;{t("i_agree_the")}&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                {t("terms_and_conditions")}
              </MDTypography>
            </MDBox>
            <MDBox mt={1} mb={1}>
              <MDButton variant="gradient" color="info" onClick={onUserSignUp} fullWidth>
                {t("sign_up")}
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                {t("already_have_an_account")}{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-in/basic"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  {t("sign_in_verbal")}
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
