export default function getCurrentDate(separator = "-", diffYear = 0) {
  const newDate = new Date();
  const date = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear() - diffYear;

  return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`;
}

export function date2ISO(inputDate) {
  if (typeof inputDate !== "string") {
    const day = inputDate.getDate();
    const month = inputDate.getMonth();
    const year = inputDate.getFullYear();
    const RefDate = Date.UTC(year, month, day);
    return new Date(RefDate).toISOString().split("T")[0];
  }

  return new Date(inputDate).toISOString().split("T")[0];
  // return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`;
}
