/*
=========================================================
* Sugimotovisa Client Area System
* based on Material Dashboard 2 PRO React - v2.1.0 by Creative Tim (www.creative-tim.com)
* Developed by Hossein Ebrahimi (hossein.ebrahimi.a@gmail.com)
=========================================================
*/

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Authentication layout components
import CoverLayout from "sugimotovisa/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";

// Multiple Language Support
import { useTranslation } from "react-i18next";

import axios from "axios";

function Cover() {
  // Translation Component
  const { t, i18n } = useTranslation();
  const [emailField, setEmailField] = useState();
  const [errorField, setErrorField] = useState(false);
  const { REACT_APP_BASE_BACKEND_URL } = process.env;
  // Notification Configuration
  const [showToast, setShowToast] = useState(false);
  const openToast = () => setShowToast(true);
  const closeToast = () => setShowToast(false);

  const onUserResetPassword = () => {
    axios({
      method: "post",
      url: `${REACT_APP_BASE_BACKEND_URL}/api/v1/auth/registration/resend-email/`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: JSON.stringify({ email: `${emailField}` }),
    })
      .then((response) => {
        if (response.status === 200) {
          setErrorField(false);
          openToast();
        }
      })
      .catch((error) => {
        setErrorField(true);
        openToast();
      });
  };

  const renderToast = (
    <MDSnackbar
      color={errorField ? "error" : "success"}
      icon={errorField ? "error" : "check"}
      title={errorField ? t("email_invalid_address") : t("email_success")}
      content={errorField ? t("email_invalid_address_content") : t("email_success_content")}
      dateTime=""
      open={showToast}
      onClose={closeToast}
      close={closeToast}
      bgWhite
    />
  );
  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      {renderToast}
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            {t("resend_verification_email")}
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            {t("resend_verification_email_message")}
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={4}>
              <MDInput
                type="email"
                label={errorField ? t("invalid_email_address") : t("email")}
                variant="standard"
                onChange={(e) => {
                  setEmailField(e.target.value);
                  setErrorField(false);
                }}
                fullWidth
                error={errorField}
              />
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton variant="gradient" color="info" onClick={onUserResetPassword} fullWidth>
                {t("reset")}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
