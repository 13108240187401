/*
=========================================================
* Sugimotovisa Client Area System
* based on Material Dashboard 2 PRO React - v2.1.0 by Creative Tim (www.creative-tim.com)
* Developed by Hossein Ebrahimi (hossein.ebrahimi.a@gmail.com)
=========================================================
*/

import { useState, useCallback, useEffect } from "react";

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDDatePicker from "components/MDDatePicker";
import MDSnackbar from "components/MDSnackbar";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import InputLabel from "@mui/material/InputLabel";

import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker, { utils } from "react-modern-calendar-datepicker";

// Data
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";

// Multiple Language Support
import { useTranslation } from "react-i18next";
//  User Info context
import { useUserInfoController, setInitialState } from "context/userInfoContext";

import axios from "axios";

import getCurrentDate, { date2ISO } from "sugimotovisa/utils/dateUtils";
import clearStorage from "sugimotovisa/utils/clearLocalStorage";

function BasicInfo() {
  // Translation Component
  const { t, i18n } = useTranslation();

  // Backend parameters
  const { REACT_APP_BASE_BACKEND_URL } = process.env;

  // UserInfo Context Call
  const [userInfoController, userInfoDispatch] = useUserInfoController();

  // Notification Configuration
  const [showToast, setShowToast] = useState(false);
  const [toastProps, setToastProps] = useState({
    color: "success",
    icon: "check",
    title: "SuccessTitle",
    content: "SuccessContent",
    dateTime: "",
  });
  const openToast = () => setShowToast(true);
  const closeToast = () => setShowToast(false);

  const minimumBirthDate = getCurrentDate("-", 100);
  const maximumBirthDate = getCurrentDate("-", 0);
  const defaultBirthDate = getCurrentDate("-", 30);

  const minimumPassportExpiryDate = getCurrentDate("-", 0);
  const maximumPassportExpiryDate = getCurrentDate("-", -5);
  const defaultPassportExpiryDate = getCurrentDate("-", -3);

  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstNameLocal, setFirstNameLocal] = useState("");
  const [middleNameLocal, setMiddleNameLocal] = useState("");
  const [lastNameLocal, setLastNameLocal] = useState("");
  const [birthDate, setBirthDate] = useState(defaultBirthDate);
  const [firstLanguage, setFirstLanguage] = useState("Persian");
  const [birthCity, setBirthCity] = useState("Tehran");
  const [birthCountry, setBirthCountry] = useState("Iran");
  const [citizenshipCountry, setCitizenshipCountry] = useState("Iran");
  const [passportNumber, setPassportNumber] = useState("X12345678");
  const [passportExpiryDate, setPassportExpiryDate] = useState(defaultPassportExpiryDate);
  const [maritalStatus, setMaritalStatus] = useState("Married");
  const [gender, setGender] = useState("Male");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("Tehran");
  const [province, setProvince] = useState("Tehran");
  const [country, setCountry] = useState("Iran");
  const [zipCode, setZipCode] = useState("1717111111");
  const [secondaryEmail, setSecondaryEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [socialNumber, setSocialNumber] = useState("");
  const [instagramProfile, setInstagramProfile] = useState("");
  const [facebookProfile, setFacebookProfile] = useState("");
  const [twitterProfile, setTwitterProfile] = useState("");

  // Load Data from Backend
  useEffect(() => {
    axios({
      method: "get",
      url: `${REACT_APP_BASE_BACKEND_URL}/api/v1/profile/user/${localStorage.email}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    })
      .then((userDataResponse) => {
        if (userDataResponse.status === 200) {
          // console.log("user data fetched");
          setFirstName(userDataResponse.data[0].first_name);
          setLastName(userDataResponse.data[0].last_name);
        }
      })
      .catch((error) => {
        // console.log("error ->", error);
      });

    axios({
      method: "get",
      url: `${REACT_APP_BASE_BACKEND_URL}/api/v1/profile/userprofile/${localStorage.userId}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    }).then((userDataResponse) => {
      if (userDataResponse.status === 200) {
        // console.log("user profile data fetched");
        const fetchedData = userDataResponse.data[0];
        setMiddleName(fetchedData.middleName);
        setFirstNameLocal(fetchedData.firstNameLocal);
        setMiddleNameLocal(fetchedData.middleNameLocal);
        setLastNameLocal(fetchedData.lastNameLocal);
        setGender(fetchedData.gender);
        setBirthDate(fetchedData.birthDate);
        setFirstLanguage(fetchedData.firstLanguage);
        setBirthCity(fetchedData.birthCity);
        setBirthCountry(fetchedData.birthCountry);
        setCitizenshipCountry(fetchedData.citizenshipCountry);
        setPassportNumber(fetchedData.passportNumber);
        setPassportExpiryDate(fetchedData.passportExpiryDate);
        setMaritalStatus(fetchedData.maritalStatus);
        setAddress1(fetchedData.address1);
        setAddress2(fetchedData.address2);
        setCity(fetchedData.city);
        setProvince(fetchedData.province);
        setCountry(fetchedData.country);
        setZipCode(fetchedData.zipCode);
        setSecondaryEmail(fetchedData.secondaryEmail);
        setMobileNumber(fetchedData.mobileNumber);
        setPhoneNumber(fetchedData.phoneNumber);
        setSocialNumber(fetchedData.socialNumber);
        setInstagramProfile(fetchedData.instagramProfile);
        setFacebookProfile(fetchedData.facebookProfile);
        setTwitterProfile(fetchedData.twitterProfile);
      }
    });
  }, []);

  // Update Database
  const saveProfileData = useCallback(() => {
    // Django UserModel Update
    axios({
      method: "put",
      url: `${REACT_APP_BASE_BACKEND_URL}/api/v1/profile/user/${localStorage.email}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
      data: JSON.stringify({
        id: `${localStorage.userId}`,
        username: `${localStorage.userName}`,
        first_name: `${firstName}`,
        last_name: `${lastName}`,
      }),
    }).then((userDataResponse) => {
      if (userDataResponse.status === 200) {
        // console.log("user data updated");
        setToastProps({
          color: "success",
          icon: "check",
          title: t("system_message"),
          content: t("data_successfully_saved"),
          dateTime: "",
        });
        openToast();
      }
    });

    const fd = new FormData();
    fd.append("middleName", `${middleName}`);
    fd.append("firstNameLocal", `${firstNameLocal}`);
    fd.append("middleNameLocal", `${middleNameLocal}`);
    fd.append("lastNameLocal", `${lastNameLocal}`);
    fd.append("birthDate", `${date2ISO(birthDate)}`);
    fd.append("firstLanguage", `${firstLanguage}`);
    fd.append("birthCity", `${birthCity}`);
    fd.append("birthCountry", `${birthCountry}`);
    fd.append("citizenshipCountry", `${citizenshipCountry}`);
    fd.append("passportNumber", `${passportNumber}`);
    fd.append("passportExpiryDate", `${date2ISO(passportExpiryDate)}`);
    fd.append("maritalStatus", `${maritalStatus}`);
    fd.append("gender", `${gender}`);
    fd.append("address1", `${address1}`);
    fd.append("address2", `${address2}`);
    fd.append("city", `${city}`);
    fd.append("province", `${province}`);
    fd.append("country", `${country}`);
    fd.append("zipCode", `${zipCode}`);
    fd.append("secondaryEmail", `${secondaryEmail}`);
    fd.append("mobileNumber", `${mobileNumber}`);
    fd.append("phoneNumber", `${phoneNumber}`);
    fd.append("socialNumber", `${socialNumber}`);
    fd.append("instagramProfile", `${instagramProfile}`);
    fd.append("facebookProfile", `${facebookProfile}`);
    fd.append("twitterProfile", `${twitterProfile}`);

    // Extended User Profile Update
    axios({
      method: "put",
      url: `${REACT_APP_BASE_BACKEND_URL}/api/v1/profile/userprofile/${localStorage.userId}`,
      headers: {
        "Content-Type": "multipart/form-data",
        // Accept: "application/json",
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
      data: fd,
    })
      .then((userDataResponse) => {
        if (userDataResponse.status === 200) {
          // console.log("user profile data updated");
          setToastProps({
            color: "success",
            icon: "check",
            title: t("system_message"),
            content: t("data_successfully_saved"),
            dateTime: "",
          });
          openToast();
        }
      })
      .catch((error) => {
        // console.log("error ->", error);
        setToastProps({
          color: "error",
          icon: "error",
          title: t("system_message"),
          content: t("data_save_error"),
          dateTime: "",
        });
        openToast();
      });
  });

  const renderToast = (
    <MDSnackbar
      color={toastProps?.color}
      icon={toastProps?.icon}
      title={toastProps?.title}
      content={toastProps?.content}
      dateTime={toastProps?.dateTime}
      open={showToast}
      onClose={closeToast}
      close={closeToast}
      bgWhite
    />
  );

  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      {renderToast}
      <MDBox p={3}>
        <MDTypography variant="h5">{t("personal_information")}</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <FormField
              label={t("first_name")}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              label={t("middle_name")}
              value={middleName}
              onChange={(e) => setMiddleName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              label={t("last_name")}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              label={t("first_name_local")}
              value={firstNameLocal}
              onChange={(e) => setFirstNameLocal(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              label={t("middle_name_local")}
              value={middleNameLocal}
              onChange={(e) => setMiddleNameLocal(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              label={t("last_name_local")}
              value={lastNameLocal}
              onChange={(e) => setLastNameLocal(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  defaultValue="Male"
                  options={selectData.gender}
                  renderInput={(params) => (
                    <FormField
                      {...params}
                      label={t("gender")}
                      InputLabelProps={{ shrink: true }}
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel>{t("date_of_birth")}</InputLabel>
                <MDDatePicker
                  value={birthDate}
                  onChange={(e) => {
                    // console.log(new Date(e[0]).toISOString().split("T")[0]);
                    setBirthDate(e[0]);
                  }}
                  options={{
                    maxDate: maximumBirthDate,
                    minDate: minimumBirthDate,
                    dateFormat: "Y-m-d",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  defaultValue="English"
                  options={selectData.language}
                  renderInput={(params) => (
                    <FormField
                      {...params}
                      label={t("first_language")}
                      InputLabelProps={{ shrink: true }}
                      value={firstLanguage}
                      onChange={(e) => setFirstLanguage(e.target.value)}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <FormField
                  label={t("birth_city")}
                  value={birthCity}
                  onChange={(e) => setBirthCity(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  defaultValue="Iran"
                  options={selectData.country}
                  renderInput={(params) => (
                    <FormField
                      {...params}
                      label={t("birth_country")}
                      InputLabelProps={{ shrink: true }}
                      value={birthCountry}
                      onChange={(e) => setBirthCountry(e.target.value)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  defaultValue="Iran"
                  options={selectData.country}
                  renderInput={(params) => (
                    <FormField
                      {...params}
                      label={t("citizenship_country")}
                      InputLabelProps={{ shrink: true }}
                      value={citizenshipCountry}
                      onChange={(e) => setCitizenshipCountry(e.target.value)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  label={t("passport_number")}
                  placeholder="X12345678"
                  value={passportNumber}
                  onChange={(e) => setPassportNumber(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel>{t("passport_expiry_date")}</InputLabel>
                <MDDatePicker
                  value={passportExpiryDate}
                  onChange={(e) => {
                    setPassportExpiryDate(e[0]);
                  }}
                  options={{
                    maxDate: maximumPassportExpiryDate,
                    minDate: minimumPassportExpiryDate,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  defaultValue="Married"
                  options={selectData.maritalStatus}
                  renderInput={(params) => (
                    <FormField
                      {...params}
                      label={t("marital_status")}
                      InputLabelProps={{ shrink: true }}
                      value={maritalStatus}
                      onChange={(e) => setMaritalStatus(e.target.value)}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormField
              label={t("address_1")}
              value={address1}
              onChange={(e) => setAddress1(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormField
              label={t("address_2")}
              value={address2}
              onChange={(e) => setAddress2(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormField
              label={t("city_town")}
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormField
              label={t("province_state")}
              value={province}
              onChange={(e) => setProvince(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              defaultValue="Iran"
              options={selectData.country}
              renderInput={(params) => (
                <FormField
                  {...params}
                  label={t("country")}
                  InputLabelProps={{ shrink: true }}
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormField
              label={t("zip_postalCode")}
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label={t("main_email")}
              value={localStorage.email}
              inputProps={{ type: "email" }}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label={t("secondary_email")}
              placeholder="example@email.com"
              inputProps={{ type: "email" }}
              value={secondaryEmail}
              onChange={(e) => setSecondaryEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              label={t("mobile_number")}
              placeholder="00989121111111"
              inputProps={{ type: "number" }}
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              label={t("phone_number")}
              placeholder="00982144332211"
              inputProps={{ type: "number" }}
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              label={t("social_phone_number")}
              placeholder="00989121111111"
              inputProps={{ type: "number" }}
              value={socialNumber}
              onChange={(e) => setSocialNumber(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormField
              label={t("instagram_profile")}
              value={instagramProfile}
              onChange={(e) => setInstagramProfile(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormField
              label={t("facebook_profile")}
              value={facebookProfile}
              onChange={(e) => setFacebookProfile(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormField
              label={t("twitter_profile")}
              value={twitterProfile}
              onChange={(e) => setTwitterProfile(e.target.value)}
            />
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <Autocomplete
              multiple
              defaultValue={["react", "angular"]}
              options={selectData.skills}
              renderInput={(params) => <FormField {...params} InputLabelProps={{ shrink: true }} />}
            />
          </Grid> */}
        </Grid>
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="flex-end"
        flexWrap="wrap"
        my={2}
        mx={2}
      >
        <MDBox ml="auto">
          <MDButton variant="gradient" color="dark" size="small" onClick={saveProfileData}>
            {t("save_data")}
          </MDButton>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default BasicInfo;
