import { useState, useCallback, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FormField from "layouts/pages/account/components/FormField";
import Autocomplete from "@mui/material/Autocomplete";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import GatheringDocumentsDiscussion from "sugimotovisa/immigration/apply/gatheringDocumentsDiscussion";

import { styled } from "@mui/material/styles";
import myStyles from "sugimotovisa/immigration/apply/myStyles.css";

import axios from "axios";
import { useUserInfoController } from "context/userInfoContext";
import { useTranslation } from "react-i18next";
import { useMaterialUIController } from "context";

function gatheringDocuments() {
  const [controller, dispatch] = useMaterialUIController();
  const { direction } = controller;

  // Translation Component
  const { t, i18n } = useTranslation();
  const { REACT_APP_BASE_BACKEND_URL } = process.env;

  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [selectedUser, setSelectedUser] = useState();
  const [appUsers, setAppUsers] = useState();

  const [gatherDocDiscussion, setGatherDocDiscussion] = useState(false);
  const [selUserAppId, setSelUserAppId] = useState(0);
  const [selUserAppDocId, setSelUserAppDocId] = useState(0);

  const [userAppDoc, setUserAppDoc] = useState([]);
  const [userApplication, setUserApplication] = useState();

  useEffect(() => {
    axios({
      method: "get",
      url: `${REACT_APP_BASE_BACKEND_URL}/api/v1/apply/userapp/`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    }).then((userAppResponse) => {
      if (userAppResponse.status === 200) {
        setUserApplication(userAppResponse.data);
        // Extract Users from application list
        const users = userAppResponse.data.map((q) => q.user);
        setAppUsers(users);
        if (users.length > 0)
          setSelectedUser(
            `AC${`000000${users[0].id}`.slice(-5)} ${users[0].first_name} ${users[0].last_name} (${
              users[0].email
            })`
          );

        setUserAppDoc([]);
        userAppResponse.data.forEach((application) => {
          const userAppId = application.id;
          axios({
            method: "get",
            url: `${REACT_APP_BASE_BACKEND_URL}/api/v1/apply/userapp/${userAppId}/doc/`,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.accessToken}`,
            },
          }).then((docResponse) => {
            if (docResponse.status === 200) {
              setUserAppDoc((prevState) => [...prevState, docResponse.data]);
            }
          });
        });
      }
    });
  }, []);
  if (gatherDocDiscussion === false)
    if (userAppDoc !== undefined && userApplication !== undefined)
      if (userAppDoc.length === userApplication.length)
        return (
          <DashboardLayout>
            <DashboardNavbar />

            {appUsers !== undefined && appUsers[0] !== undefined ? (
              <>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  flexWrap="wrap"
                  my={2}
                  mx={2}
                >
                  <Grid container spacing={6}>
                    <Grid item xs={12} sm={5}>
                      <Autocomplete
                        defaultValue={`AC${`000000${appUsers[0].id}`.slice(-5)} ${
                          appUsers[0].first_name
                        } ${appUsers[0].last_name} (${appUsers[0].email})`}
                        options={appUsers
                          .map(
                            (value, key) =>
                              `AC${`000000${value.id}`.slice(-5)} ${value.first_name} ${
                                value.last_name
                              } (${value.email})`
                          )
                          .filter((itm, pos, self) => self.indexOf(itm) === pos)}
                        renderInput={(params) => (
                          <FormField
                            {...params}
                            label={t("select_user")}
                            InputLabelProps={{ shrink: true }}
                            value={selectedUser}
                            onSelect={(e) => setSelectedUser(e.target.value)}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  flexWrap="wrap"
                  my={2}
                  mx={2}
                >
                  <MDBox ml="auto">
                    <div>
                      <p>{selectedUser}</p>
                    </div>
                  </MDBox>
                </MDBox>
              </>
            ) : null}

            {userApplication.map((application) =>
              `AC${`000000${application.user.id}`.slice(-5)} ${application.user.first_name} ${
                application.user.last_name
              } (${application.user.email})` === selectedUser ? (
                <Accordion
                  expanded={expanded === `panel${application.id}`}
                  onChange={handleChange(`panel${application.id}`)}
                  // dir={
                  //   application.applyProgram === null
                  //     ? "ltr"
                  //     : application.applyProgram.descriptionLanguage.rtl === true
                  //     ? "rtl"
                  //     : "ltr"
                  // }
                  dir="ltr"
                  key={application.id}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${application.id}bh-content`}
                    id={`panel${application.id}bh-header`}
                  >
                    <Typography sx={{ width: "50%", flexShrink: 0 }}>
                      {application.applyProgram === null
                        ? application.description
                        : application.applyProgram.name}
                    </Typography>
                    <Typography sx={{ color: "text.secondary" }}>
                      {application.applyProgram === null
                        ? " "
                        : `${application.applyProgram.school}, ${application.applyProgram.city}, ${application.applyProgram.province}, ${application.applyProgram.country}`}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {userAppDoc.map((uDocs) => {
                      let docs = [];
                      for (let i = 0; i < uDocs.length; i += 1) {
                        if (application.id === uDocs[i].userApp.id) {
                          docs = [...docs, uDocs[i]];
                        }
                      }
                      return docs.map((doc) => (
                        <Box
                          key={doc.id}
                          dir={direction}
                          sx={{
                            width: "100%",
                            height: 100,
                            backgroundColor: "#FFFFFF",
                            "&:hover": {
                              backgroundColor: "#9FA8DA",
                              opacity: [0.9, 0.8, 0.7],
                            },
                            my: 1,
                          }}
                        >
                          <div className={doc.status === "Approved" ? "rowResolved" : "row"}>
                            {/* Required / Optional */}
                            <div className={doc.required ? "paneRequired" : "paneOptional"}>
                              <span className="h-text">
                                {doc.required ? t("required") : t("optional")}
                              </span>
                            </div>
                            {/* Status: Approved Missing Rejected */}
                            <div className={`paneStatus${doc.status}`}>
                              <span className="h-text">
                                {t(`status.${doc.status.toString().toLowerCase()}`)}
                              </span>
                            </div>
                            {/* Main Pane */}
                            <div
                              className={
                                doc.status === "Approved" ? "dividerPaneResolved" : "dividerPane"
                              }
                            />
                            <div
                              className={
                                doc.status === "Approved" ? "mainPaneResolved" : "mainPane"
                              }
                            >
                              {t("document_type")}&nbsp;:&nbsp;{doc.document}
                            </div>
                            <div
                              className={
                                doc.status === "Approved"
                                  ? "descriptionPaneResolved"
                                  : "descriptionPane"
                              }
                            >
                              <p>
                                <p style={{ fontFamily: "inherit", display: "flex" }} dir="ltr">
                                  {doc.description}
                                </p>
                              </p>
                            </div>
                            <div
                              className={
                                doc.status === "Approved" ? "dividerPaneResolved" : "dividerPane"
                              }
                            />
                            <div>
                              <Button
                                onClick={() => {
                                  setSelUserAppId(doc.userApp.id);
                                  setSelUserAppDocId(doc.id);
                                  setGatherDocDiscussion(true);
                                }}
                              >
                                {t("see_details")}
                              </Button>
                            </div>
                          </div>
                        </Box>
                      ));
                    })}
                  </AccordionDetails>
                </Accordion>
              ) : null
            )}
            <div>
              <h5> &nbsp; </h5>
              <h5> &nbsp; </h5>
              <h5> &nbsp; </h5>
            </div>
            <Footer />
          </DashboardLayout>
        );

  if (gatherDocDiscussion === true)
    return (
      <GatheringDocumentsDiscussion
        userAppId={selUserAppId}
        docId={selUserAppDocId}
        selectedUser={selectedUser}
      />
    );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Footer />
    </DashboardLayout>
  );
}

export default gatheringDocuments;
